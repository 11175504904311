import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import SEO from 'sections/Layout/SEO';

import {
  email,
  inner,
  imageWrap,
  links,
  email2,
} from 'styles/pages/digitec/email.module.scss';

const seo = {
  title: 'digitec.ch Survey | Promo | Screver Feedback Management Solution',
  metaDesc:
    'Look at the digitec.ch email with our Screver Feedback Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
  opengraphUrl: 'https://screver.com/cases/cx/email-2/',
};

const Email1 = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <SEO seo={seo} themeColor="#ffffff" />

      <div className={classnames(email, email2, 'scr-digitect-wrap')}>
        <div className={inner}>
          <div className={classnames(imageWrap, email2)}>
            <img
              src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-email-2-inner.png"
              alt=""
            />

            <div className={classnames(links, email2)}>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 0/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 1/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 2/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 3/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 4/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 5/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 6/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 7/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 8/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 9/10"
              ></a>
              <a
                href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Rate as 10/10"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email1;
